<template>
  <div v-drag>
    <a-modal v-model:visible="dialogVisible"
             :maskClosable="false"
             title="收藏"
             :closable="false"
             @cancel="cancel"
             @ok="create"
             width="400px">
      <a-row class="input_Warp">
        <a-textarea placeholder="请输入"
                    type="textarea"
                    :rows="4"
                    style="width:100%"
                    v-model:value="form.tags" />
      </a-row>

    </a-modal>
  </div>
</template>

<script setup>
import { reactive, ref } from "@vue/reactivity";
import { collectTopic } from '@/api/collect.js'
import { message } from "ant-design-vue";
let dialogVisible = ref(false)
let form = reactive({})
const emit = defineEmits(['updateID'])


const setData = (pForm) => {
  for (const key in pForm) {
    form[key] = pForm[key]
  }
}
const cancel = () => {
  dialogVisible.value = false
}
const create = async () => {
  const {data} =await collectTopic(form)
  emit('updateID', data.question_favorite_id)
  message.success("收藏成功")
  cancel()
}

defineExpose({
  dialogVisible, setData
})
</script>

<style lang="scss" scoped>
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 23px;
  font-size: 16px;
  padding: 27px;
  padding-top: 0;
}
.btn_warp {
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.input_Warp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
</style>