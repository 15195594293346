import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '../store'

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/login'
  },
  {
    path: '/code',
    name: '/code',
    component: () => import('@/views/code/index.vue')
  },
  {
    path: '/code2',
    name: '/code2',
    component: () => import('@/views/code/index2.vue')
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/loginCode',
    name: '/loginCode',
    component: () => import('@/views/loginCode/index.vue')
  },
  {
    path: '/register',
    name: '/register',
    component: () => import('@/views/register/index.vue')
  },
  {
    path: '/home',
    name: '/home',
    component: () => import('@/views/home/index.vue'),
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: '/index',
        component: () => import('@/views/index/index.vue')
      },
      {
        // 优质课程
        path: '/course',
        name: '/course',
        component: () => import('@/views/course/index.vue')
      },
      {
        // 优质课程
        path: '/course_qiye',
        name: '/course_qiye',
        component: () => import('@/views/course_qiye/index.vue')
      },
      {
        // 学习中心
        path: '/lerningCenter',
        name: '/lerningCenter',
        component: () => import('@/views/lerningCenter/index.vue')
      },

      {
        // 分析报告
        path: '/analyze',
        name: '/analyze',
        component: () => import('@/views/analyze/index.vue')
      },
      {
        // 我的
        path: '/userInfo',
        name: '/userInfo',
        component: () => import('@/views/userInfo/index.vue'),
        redirect: "/userInfo/info",
        children: [
          {
            // 个人信息
            path: '/userInfo/info',
            name: '/userInfo/info',
            component: () => import('@/views/userInfo/components/info.vue')
          },
          {
            // 中考目标
            path: '/userInfo/goal',
            name: '/userInfo/goal',
            component: () => import('@/views/userInfo/components/goal.vue')
          },
          {
            // 学习信息
            path: '/userInfo/learningInfo',
            name: '/userInfo/learningInfo',
            component: () => import('@/views/userInfo/components/learningInfo.vue')
          },
          {
            // 科目模式
            path: '/userInfo/subjectMode',
            name: '/userInfo/subjectMode',
            component: () => import('@/views/userInfo/components/subject.vue')
          },
          {
            // 修改密码
            path: '/userInfo/resetPassword',
            name: '/userInfo/resetPassword',
            component: () => import('@/views/userInfo/components/editPassword.vue')
          },
          {
            // 联系我们
            path: '/userInfo/contact',
            name: '/userInfo/contact',
            component: () => import('@/views/userInfo/components/contact.vue')
          },
          {
            // 用户协议
            path: '/userInfo/deal',
            name: '/userInfo/deal',
            component: () => import('@/views/userInfo/components/deal.vue')
          },
          {
            // 帮助
            path: '/userInfo/help',
            name: '/userInfo/help',
            component: () => import('@/views/userInfo/components/help.vue')
          },
          {
            // 反馈
            path: '/userInfo/feedback',
            name: '/userInfo/feedback',
            component: () => import('@/views/userInfo/components/feedback.vue')
          },

        ]
      },
    ]
  },
  {
    // 题目信息
    path: '/showTopic',
    name: '/showTopic',
    component: () => import('@/views/showTopic/index.vue')
  },
  {
    // 优质课程-资源预览
    path: '/course/preview',
    name: '/course/preview',
    component: () => import('@/views/course/preview.vue')
  },
  {
    // 优质课程-复习讲义-更多
    path: '/course/reviewHandout',
    meta: {},
    name: '/course/reviewHandout',
    component: () => import('@/views/course/reviewHandout.vue')
  },
  {
    // 优质课程-视频讲解-更多
    path: '/lecture',
    name: '/lecture',
    component: () => import('@/views/lecture/index.vue'),
  },
  {
    // 优质课程-本校视频
    path: '/shcoolVideo',
    name: '/shcoolVideo',
    component: () => import('@/views/shcoolVideo/index.vue'),
  },
  {
    // 整卷测试，选择模板
    path: '/testpaper/template',
    meta: {
      keepAlive: true
    },
    name: '/testpaper/template',
    component: () => import('@/views/paperTest/index.vue'),
  },
  {
    // 整卷测试，列表
    path: '/testpaper/list',
    name: '/testpaper/list',
    component: () => import('@/views/paperTest/components/paperlist.vue'),
  },
  {
    // 整卷测试，做题详情1
    path: '/doPaper',
    name: '/doPaper',
    component: () => import('@/views/doPaper/index.vue'),
  },
  {
    // 做题的时候的答题卡
    path: '/testpaper/card',
    name: '/testpaper/card',
    component: () => import('@/views/doPaperCard/index.vue'),
  },

  {
    // 知识点曲线
    path: '/knowledge/line',
    meta: {
    },
    name: '/knowledge/line',
    component: () => import('@/views/knowledgeLine/index.vue'),
  },
  {
    // 知识点主页
    path: '/chapter',
    meta: {
      keepAlive: true
    },
    name: '/chapter',
    component: () => import('@/views/chapter/index.vue'),
  },

  {
    // 学情分析
    path: '/studentAnalysis',
    meta: {
    },
    name: '/studentAnalysis',
    component: () => import('@/views/studentAnalysis/index.vue'),
  },
  {
    // 我的试卷
    path: '/mytestpaper',
    name: '/mytestpaper',
    meta: {
      keepAlive: true
    },
    component: () => import('@/views/mytestpaper/index.vue'),
  },
  {
    // 教师试卷(学习中心-精选试卷)
    path: '/teacherPaper',
    meta: {
      keepAlive: true
    },
    name: '/teacherPaper',
    component: () => import('@/views/teacherPaper/index.vue'),
  },
  {
    // 思维导图
    path: '/mapMind',
    meta: {
      keepAlive: true
    },
    name: '/mapMind',
    component: () => import('@/views/mapMind/index.vue'),
  },
  {
    //学习中心 答疑>更多
    path: '/dayi',
    meta: {
    },
    name: '/dayi',
    component: () => import('@/views/dayi/index.vue'),
  },
  ,
  {
    // 学习中心 答疑>更多>帖子详情
    path: '/dayi/info',
    name: '/dayi/info',
    meta: {
      keepAlive: false
    },
    component: () => import('@/views/dayi/components/info.vue'),
  },
  {
    //学习中心> 自由选题
    path: '/freedomTopic',
    name: '/freedomTopic',
    meta: {
      // keepAlive: true
    },
    component: () => import('@/views/freedomTopic/index.vue'),
  },
  {
    //学习中心> 学习进度
    path: '/learnProgress',
    name: '/learnProgress',
    meta: {
      // keepAlive: true
    },
    component: () => import('@/views/learnProgress/index.vue'),
  },
  {
    //学习中心> 自由选题测试
    path: '/freedomTopic_cs',
    name: '/freedomTopic_cs',
    component: () => import('@/views/freedomTopic/index1.vue'),
  },

  {
    // 答案解析
    path: '/analysis',
    name: '/analysis',
    component: () => import('@/views/analysis/index.vue'),
  },
  {
    // 答案解析
    path: '/analysis/card',
    name: '/analysis/card',
    component: () => import('@/views/analysisCard/index.vue'),
  },
  {
    // 错题本主页
    path: '/errorBook',
    meta: {
      keepAlive: true
    },
    name: '/errorBook',
    component: () => import('@/views/errorBook/index.vue'),
  },
  {
    // 错题本-收藏本列表
    path: '/errorBook/collect',
    meta: {
      keepAlive: true
    },
    name: '/errorBook/collect',
    component: () => import('@/views/errorBook/CollectIndex.vue'),
  },
  // {
  //   // 错题本主页
  //   path: '/errorBook/info',
  //   meta: {
  //   },
  //   name: '/errorBook/info',
  //   component: () => import('@/views/errorBook/components/info.vue'),
  // },
  {
    // 错题本/收藏本详情
    path: '/errorBook/errorInfo',
    meta: {
    },
    name: '/errorBook/errorInfo',
    component: () => import('@/views/errorBook/components/errorInfo.vue'),
  },
  {
    // 模板组卷
    path: '/templatePaper',
    meta: {
    },
    name: '/templatePaper',
    component: () => import('@/views/templatePaper/index.vue'),
  },

  {
    // 知识点主页
    path: '/knowledge',
    meta: {
      keepAlive: true
    },
    name: '/knowledge',
    component: () => import('@/views/knowledge/index.vue'),
  },
  {
    path: '/knowledgeWeak',
    meta: {
      keepAlive: true
    },
    name: '/knowledgeWeak',
    component: () => import('@/views/knowledgeWeak/index.vue'),
  },
  {
    // 自评页面
    path: '/selfCorrect',
    meta: {},
    name: '/selfCorrect',
    component: () => import('@/views/selfCorrect/index.vue'),
  },
  {
    // 自评 答题卡
    path: '/selfCard',
    meta: {
    },
    name: '/selfCard',
    component: () => import('@/views/selfCorrect/selfCard.vue'),
  },
  {
    // 能力素养主页
    path: '/capability',
    meta: {
      keepAlive: true
    },
    name: '/capability',
    component: () => import('@/views/capability/index.vue'),
  },
  // 消息列表
  {
    path: '/message',
    meta: {
      keepAlive: false
    },
    name: 'message',
    component: () => import('@/views/components/message/index.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

//路由白名单 ,配置的路由直接放行
router.beforeEach((to, from, next) => {
  store.commit('loading/clearSpinning')
  NProgress.start()
  let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
  if (to.path == '/login' || !userInfo) {
    window.sessionStorage.clear()
  }
  next()
})

router.afterEach(() => {
  // 完成进度条
  NProgress.done()
})

export default router
