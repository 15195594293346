
<template>
  <div>
     <button type="primary" class="video_btn" @click="openvideo">
      系统介绍视频
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 2.625C11.7502 2.625 9.551 3.29213 7.68039 4.54203C5.80978 5.79193 4.35182 7.56847 3.49088 9.64698C2.62993 11.7255 2.40467 14.0126 2.84357 16.2192C3.28248 18.4257 4.36584 20.4525 5.95667 22.0433C7.54749 23.6342 9.57432 24.7175 11.7809 25.1564C13.9874 25.5953 16.2745 25.3701 18.353 24.5091C20.4315 23.6482 22.2081 22.1902 23.458 20.3196C24.7079 18.449 25.375 16.2498 25.375 14C25.3712 10.9843 24.1716 8.09323 22.0392 5.96082C19.9068 3.8284 17.0157 2.62876 14 2.625ZM17.9856 14.7284L12.7356 18.2284C12.5916 18.3239 12.4228 18.3748 12.25 18.375C12.0179 18.375 11.7954 18.2828 11.6313 18.1187C11.4672 17.9546 11.375 17.7321 11.375 17.5V10.5C11.3749 10.3415 11.4178 10.186 11.4992 10.0501C11.5806 9.91412 11.6973 9.80281 11.837 9.72802C11.9767 9.65324 12.1341 9.61779 12.2923 9.62546C12.4506 9.63312 12.6038 9.68362 12.7356 9.77156L17.9856 13.2716C18.1057 13.3514 18.2041 13.4597 18.2722 13.5868C18.3402 13.7139 18.3759 13.8558 18.3759 14C18.3759 14.1442 18.3402 14.2861 18.2722 14.4132C18.2041 14.5403 18.1057 14.6486 17.9856 14.7284Z" fill="white"/>
          </svg>
     </button>
     <iframe src="https://sunraydtk.oss-cn-beijing.aliyuncs.com/ddup_help/%E5%A4%A9%E5%A4%A9%E8%BF%9B%E6%AD%A5%E4%B8%AD%E8%80%83%E6%99%BA%E8%83%BD%E5%A4%87%E8%80%83%E7%B3%BB%E7%BB%9F%E5%AD%A6%E7%94%9F%E7%89%88%EF%BC%88PC%E7%AB%AF%EF%BC%89.pdf#toolbar=0"
          width="100%"
          height="100%"></iframe>
    <!-- <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_01.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_02.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_03.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_04.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_05.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_06.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_07.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_08.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_09.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_10.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_11.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_12.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_13.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_14.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_15.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_16.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_17.png"
         alt=""> -->
    <VideoDialog ref="VideoRef"></VideoDialog>
  </div>
</template>

<script>
import VideoDialog from "@/views/home/components/videoDialog.vue"

export default {
  components: {
    VideoDialog,
  },
  data () {
    return {

    }
  },
  methods: {
    openvideo () {
      this.$refs.VideoRef.neverShowBtn = false
      this.$refs.VideoRef.dialogVisible = true
      this.$refs.VideoRef.initData()
    },
  }
}
</script>

<style lang="scss" scoped>
div {
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 86rem;
}
img {
  max-width: 1160rem;
}
.video_btn {
  cursor: pointer;
  gap: 6px;
  border: 0px;
  border-radius: 8px;
  background: rgb(33, 150, 243);
  width: 234px;
  height: 56px;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
</style>