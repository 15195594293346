import axios from 'axios'
// import basicUrl from './basicUrl'
import router from '@/router'
import { message } from 'ant-design-vue';
import getMd5Form from '@/utils/getMd5Form.js'
import { rpcAuth } from '@/api/rpcauth.js'

var basicUrl = process.env.NODE_ENV === 'development' ? "http://10.10.11.250:805" : 'https://slbk.sunrayai.cn'

export const instances = axios.create({
  baseURL: basicUrl,
  timeout: 3600000,
});

export const http_file = axios.create({
  baseURL: basicUrl,
  timeout: 3600000,
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  transformRequest: [(data) => {
    const formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key])
    }
    return formData
  }]
})

// 设置放行路由，放行错误控制
var role = [
  '/api/v1/student/export_stat',
  '/api/v1/combination/stats_export',
  '/api/v1/my/print_pdf'
]

export var requestSign = {
  number: 0,
  setNumber: function (number) {
    requestSign.number = number
  }
}

async function getToken() {
  const res = await rpcAuth();
  return res
}

function closeLoading () {
  // return
  setTimeout(() => {
    requestSign.number -= 1
    if (requestSign.number < 0) {
      requestSign.number = 0
    }
    if (requestSign.number == 0) {
      loadings.close()
    }
  }, 500);
}
function addInterceptors (face) {
  face.interceptors.request.use(async config => {
    let userInfo = JSON.parse(window.localStorage.getItem('appToken'))
    if (userInfo && userInfo.exp > Math.floor(Date.now() / 1000)) {
      config.headers['Authorization'] = `Bearer ${userInfo.token}`
    } else {
      window.localStorage.removeItem('appToken')
      await getToken().then(res => {
        window.localStorage.setItem('appToken', JSON.stringify(res.data))
        config.headers['Authorization'] = `Bearer ${res.data.token}`
      }).catch(err => {
        console.log(err);
      })
    }

    if (config.method == 'get') {
      if (!config.params) {
        config.params = {}
      }
      config.params.sign = getMd5Form(config.params, config.url)
    } else {
      if (!config.data) {
        config.data = {}
      }
      config.data.sign = getMd5Form(config.data, config.url)
    }

    // openLoading()
    return config
  })
  face.interceptors.response.use(async config => {
    // closeLoading()

    if (role.findIndex(item => item == config.config.url) != -1) {
      return config
    }

    if (config.config.url.includes('/api/rpc/') && config.status == 410001) {
      await getToken().then(res => {
        window.localStorage.setItem('appToken', JSON.stringify(res.data))
      }).catch(err => {
        console.log(err);
      })
    }

    if (config.data.status === undefined && config.data.msg === undefined) {
      return config.data
    }

    config = config.data
    return await new Promise((resolve, reject) => {
      if (config.status == 200) {
        resolve(config)
      } else {
        // 当状态码不为200的时候，直接拦截
        if (config.msg == 'error') {
          message.error(config.data.msg)
        } else {
          message.error(config.msg)
        }
        if (config.status == 410000) {
          window.localStorage.clear()
          router.push('/login?not_auto_login=1')
        }
        reject(config)
      }
    })
  })
}

addInterceptors(instances)
addInterceptors(http_file)
