<template>
  <div class="cuotiben_Warp">
    <div class="top2"
         @click='openDialog'>
      导出收藏
    </div>
    <div class="top">
      <div class="del"
           v-if="!showCheck"
           @click="del">编辑</div>
      <div class="del"
           v-else
           @click="del">删除</div>
    </div>

    <COMMONTOPSEARCH ref="commonTopSearchRef"
                     @initData="getPaperList"
                     :isDefault="false"
                     :url="'/api/v1/problems/favorite_subject'"
                     :showTag="showTag" />

    <div class="topic_warp">
      <div class="topic_item_wrap"
           v-for="(item,index) in paperList"
           :key="index">
        <div class="topic"
             @click="toInfo(item)">
          <div class="checked"
               :style="{'border':  checkIds.indexOf(item.question_favorite_id) != -1 ?'none':''}"
               v-if="showCheck">
            <img src="@/static/cuotiben/chcked.png"
                 v-if="checkIds.indexOf(item.question_favorite_id) != -1"
                 alt="">
          </div>
          <div class="question_type">{{item.question_type_name}}</div>
          <div class="pre_content"><span style="color:#999">题目内容:</span>{{ contentHtml(item.question_content)}}</div>

          <div class="comment"><span style="color:#999">笔记:</span>{{item.favorite_tags || '无'}}</div>
          <div class="btn"
               @click="toInfo(item)">
            查看详情
          </div>
          <div class="time">
            {{item.time_input}}
          </div>
        </div>
      </div>
      <!-- 布局占位 -->
      <div class="topic_item_wrap"
           style="height:0"></div>
      <div class="topic_item_wrap"
           style="height:0"></div>
    </div>
    <Check ref="checkRef"
           @exportData='exportError' />
  </div>
</template>

<script setup>
import COMMONTOPSEARCH from '@/views/components/COMMON_TOP_SEARCH/index.vue'
import { ref, onMounted } from 'vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { getList, delRow, exportWrongPrint } from '@/api/errorBook.js'
import { message, Modal } from 'ant-design-vue'
import Check from './components/check.vue'

const route = useRoute()
const router = useRouter()

const commonTopSearchRef = ref(null)
let showTag = ref(true)
let paperList = ref([])
let checkIds = ref([])
let showCheck = ref(false)
let questionIds = ref([])
let subject_id = ref('')
let sessionData = ref()
const contentHtml = (content) => {
  if (!content) return
  // 富文本编辑器的内容只获得文字去掉标签
  content = content.replace(/<[^>]+>/g, "").replace(/&nbsp;/ig, "").replace(/(\n)/g, "");
  return content
}
const setSessionData = (params) => {
  window.sessionStorage.setItem(route.path, JSON.stringify(params))
}
const getSessionData = () => {
  if (window.sessionStorage.getItem(route.path)) {
    sessionData.value = JSON.parse(window.sessionStorage.getItem(route.path))
  }

}

onBeforeRouteLeave((to, from, next) => {
  if (to.path != '/errorBook/errorInfo') {
    window.sessionStorage.removeItem(route.path)
  }
  next()
})

onMounted(() => {
  getSessionData()
})
const getPaperList = async (id, keywords, subjectName, tagId) => {
  questionIds.value = []
  let params
  if (!sessionData.value) {
    params = {
      subject_id: id,
      favorite_tags: keywords,
      question_favorite_type_id: tagId
    }
    subject_id.value = id
  }
  else {
    params = sessionData.value
    if (params) {
      commonTopSearchRef.value.subject_id = params.subject_id
    }
    subject_id.value = params.subject_id
    sessionData.value = ''
  }
  const { data } = await getList(params)
  setSessionData(params)
  paperList.value = Object.values(data.list)
}
let checkRef = ref()
const openDialog = () => {
  if (showCheck.value) {
    checkRef.value.dialogVisible = true
  } else {
    message.error('请先点击编辑选择错题')
  }

}
const exportError = async (radio) => {
  if (checkIds.value.length == 0) {
    change()
  } else {
    Modal.confirm({
      title: '提示',
      content: '确定导出错题？',
      async onOk () {


        const data = await exportWrongPrint({ question_ids: questionIds.value, detail: radio })
        const a = document.createElement("a")
        a.href = window.URL.createObjectURL(data)
        a.download = `错题本.pdf`
        a.click()
        // commonTopSearchRef.value.refresh()
        change()
      },
      onCancel () {
        change()
      },
    });

  }
}

const del = async () => {
  if (checkIds.value.length == 0) {
    change()
  } else {
    Modal.confirm({
      title: '提示',
      content: '确定从收藏本移除选中题目？',
      async onOk () {
        await delRow({ ids: checkIds.value.join(',') })
        message.success('删除成功')
        commonTopSearchRef.value.refresh()
        change()
      },
      onCancel () {
        change()
      },
    });

  }
}

const change = () => {
  showCheck.value = !showCheck.value
  checkIds.value = []
  questionIds.value = []
}

const toInfo = (row) => {
  if (showCheck.value) {
    if (checkIds.value.indexOf(row.question_favorite_id) == -1) {
      checkIds.value.push(row.question_favorite_id)
      questionIds.value.push(row.question_id)
    } else {
      checkIds.value.splice(checkIds.value.indexOf(row.question_favorite_id), 1)
      questionIds.value.splice(questionIds.value.indexOf(row.question_id), 1)
    }
  } else {
    router.push('/errorBook/errorInfo?id=' + row.question_id + '&subject_id=' + subject_id.value + '&noShow=' + true + '&question_favorite_id='+row.question_favorite_id)
  }
}
</script>

<style lang="scss" scoped>
.cuotiben_Warp {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  background-image: url("../../static/error/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .top2 {
    position: absolute;
    margin-top: 40rem;
    right: 152rem;
    padding: 6rem 24rem 8rem;
    line-height: 36rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: 400;
    font-size: 24rem;
    border-radius: 120rem;
    background: #3e8ef3;
    cursor: pointer;
  }
  .top {
    position: absolute;
    margin-top: 40rem;
    right: 40rem;
    display: flex;
    justify-content: flex-end;
  }
  .topic_warp {
    width: calc(100vw - 100rem);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    margin-top: 20px;
    padding-left: 70rem;
    align-content: flex-start;
    max-height: calc(100vh - 338rem);
    .topic_item_wrap {
      margin: 20px auto 0;
      padding: 0 10rem;
      width: 538rem;
      height: 288rem;
    }
    .topic {
      width: 100%;
      height: 100%;
      background-image: url("../../static/cuotiben/card1.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-right: 30rem;
      padding: 24rem;
      position: relative;
      .checked {
        position: absolute;
        right: 17px;
        top: 40px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #bbbbbb;
        img {
          width: 20px;
          height: 20px;
        }
      }
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .question_type {
        height: 36rem;
        width: 100%;
        line-height: 36rem;
        font-size: 24rem;
        font-weight: 500;
        color: #000000;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
        display: block;
      }
      .comment,
      .pre_content {
        // margin: 0 20rem;
        display: flex;
        font-weight: 500;
        font-size: 20rem;
        margin-top: 10rem;
        font-weight: 400;
        line-height: 30rem;
        align-items: baseline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        flex: 1;
        color: #666666;
        span {
          padding-right: 5rem;
        }
      }
      .comment {
        height: 30rem;
        -webkit-line-clamp: 1;
      }
      .pre_content {
        margin-bottom: 8rem;

        height: 60rem;
        -webkit-line-clamp: 2;
      }
      .btn {
        width: fit-content;
        // height: 44rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20rem;
        font-weight: 400;
        color: #ffffff;
        background: #ff654e;
        border-radius: 60rem;
        margin: 18rem auto 8rem;
        padding: 9rem 32rem;
        line-height: 30rem;
        cursor: pointer;
      }
      .time {
        font-weight: 400;
        font-size: 16rem;
        color: #999999;
      }
    }
  }
}

.del {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 400;
  font-size: 24rem;
  border-radius: 35rem;
  padding: 6rem 24rem 8rem;
  line-height: 36rem;
  background: #3e8ef3;
  cursor: pointer;
}
.active {
  color: white;
  font-weight: bold;
}
:deep .ant-pagination-total-text {
  color: #fff;
}
:deep .ant-pagination-options-quick-jumper {
  color: #fff;
}
</style>