<template>
  <div class="home_warp">
    <!-- 路由含fromH 则本页面是在高中的iframe元素，
          无需含高中iframe，避免多重嵌套  -->
    <iframe id="myIframe"
            v-if="!route.query.fromH"
            :src="highUrl+'/login?fromM=1'"
            style="display:none" />
    <div class="login_warp">
      <div class="title">
        天天进步智能备考系统
        <!-- 中考智能学伴 -->
      </div>
      <div class="level">
        <span style="color:#2196f3"
              class="level_active">初中</span>
        <span style="color:#999;cursor:pointer"
              class="level_normal"
              @click="toHeight">高中</span>
      </div>
      <a-form :model="form">
        <a-row>
          <a-input class="input_class"
                   v-model:value="form.account"
                   placeholder="请输入账号">
            <template #prefix>
              <img class="icons"
                   src="../../static/login/账号.png"
                   alt="">
            </template>
          </a-input>
        </a-row>
        <a-row>
          <a-input class="input_class"
                   v-model:value="form.code"
                   placeholder="请输入验证码">
            <template #prefix>
              <img class="icons"
                   src="../../static/login/ShieldCheck.png"
                   alt="">
            </template>
            <template #suffix>
              <van-button size="small"
                          class="btns_sms"
                          style=""
                          @click="sendSms"
                          native-type="button"
                          v-show="num==0"> 发送验证码</van-button>
              <span v-show="num>0"
                    style="color:#ccc;line-height:56rem">
                {{num}}s后重新获取
              </span>
            </template>
          </a-input>
        </a-row>
        <!-- <div class="check_warp">
          <div>
            <a-checkbox :checked="opertionForm.rememberPassword"
                        @change="rememberPassword">记住密码</a-checkbox>
          </div>
          <div>
            <a-checkbox :checked="opertionForm.autoLogin"
                        @change="autoLogin">自动登录</a-checkbox>
          </div>
        </div> -->
        <div class="check_warp">
          <div>
            <a-checkbox :checked="opertionForm.consent"
                        @change="opertionForm.consent=!opertionForm.consent">同意</a-checkbox><span style=""
                  class="protocol"
                  @click="showDeal">《用户协议》
            </span>
          </div>
        </div>
      </a-form>
      <div class="btn_warp">
        <a-button class="btn"
                  type="info"
                  @click="toHome">登 录</a-button>
        <div class="btn_group">
          <a-button class="btn_text"
                    type="text"
                    @click="toRegister"><span>免费注册</span></a-button>
          <div class="btn_text">|
          </div>

          <a-button class="btn_text"
                    type="text"
                    @click="toLoginP"><span>密码登录</span></a-button>

        </div>
        <a-button class="btn_text"
                  type="text"
                  @click="toActivate"><span>学习卡激活</span></a-button>
      </div>

    </div>
    <a-modal v-model:visible="dialogVisible"
             :maskClosable="false"
             :closable="true"
             :footer="null"
             width="1050rem">
      <deal />

      <div class="btn_warp">
        <a-button @click="handleClose"
                  type="primary">
          关闭
        </a-button>

      </div>
    </a-modal>

  </div>
</template>

<script setup>
import CryptoJS from 'crypto-js';
import { loginApi } from '@/api/login.js'
import { reactive, ref, toRef, toRefs } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import highUrl from '@/utils/highUrl.js'
import deal from '@/views/userInfo/components/deal.vue'
import { getUserSubject } from '@/api/subject.js'
import { loginVerify } from '@/api/activate.js'


let secretkey = 'abc'
const router = useRouter()
const route = useRoute()
let intervalId = ref(null)

let form = reactive({
  // account: '111111',
  // password: '111111'
  sms_record_id: null
})
let num = ref(0)

let opertionForm = reactive({
  rememberPassword: false,
  autoLogin: false,
  consent: true
})
let subjectList = ref([])
let dialogVisible = ref(false)
let subjectListData = ref([])
onMounted(() => {
  // initInfo()
  getHData()
})

const showDeal = () => {
  dialogVisible.value = true
}
const handleClose = () => {
  dialogVisible.value = false
}
const sendSms = async () => {
  if (!form.account) {
    message.warning('请输入手机号');
    return;
  }
  try {
    if (form.account) {

      const { data } = await loginVerify({
        mobile: form.account,

      });
      form.sms_record_id = data.sms_record_id;
      console.log('data', data);

      if (data.sms_record_id) {
        message.success('发送成功');
        num.value = 59;
        intervalId.value = setInterval(() => {
          num.value -= 1;
          if (num.value == 0) {
            clearInterval(intervalId.value);
          }
        }, 1000);

      }

    } else if (form.grade_id === 2) {
      // 初中
      await loginVerifyM({
        mobile: form.account,
        type: 'register2'
      });
      Toast({
        message: '发送成功',
        position: 'top',
        className: 'toast_class'
      });
      num.value = 59;
      intervalId.value = setInterval(() => {
        num.value -= 1;
        if (num.value == 0) {
          clearInterval(intervalId.value);
        }
      }, 1000);
    }
  } catch (err) {
    // console.error(err);
  }
}
const getHData = () => {
  var aData = localStorage.getItem("aPageData");
  if (aData) {
    setHData(aData);     // 当能获取到数据时就说明是从高中页面跳转过来的
    localStorage.removeItem("aPageData");
  } else if (route.query.fromH) {
    // 在高中iframe中监听
    window.addEventListener("message", receiveMessage, false);
  } else {
    initInfo()
  }
  function receiveMessage (event) {
    if (event.origin !== highUrl && event.origin + '/#' !== highUrl) return;
    if (event.data) {
      localStorage.setItem("aPageData", event.data);
    }
  }
}

const setHData = (data) => {
  let postData = JSON.parse(data)
  form.account = postData.form.phone
  form.password = postData.form.password
  opertionForm.rememberPassword = postData.opertionForm.rememberPassword
  opertionForm.autoLogin = postData.opertionForm.autoLogin || false
}

const initInfo = () => {
  let cacheForm = JSON.parse(window.localStorage.getItem('loginForm'))
  let cacheOpertionForm = JSON.parse(window.localStorage.getItem('opertionForm'))
  if (cacheOpertionForm) {
    // opertionForm = reactive(cacheOpertionForm)
    opertionForm.rememberPassword = cacheOpertionForm.rememberPassword
    opertionForm.autoLogin = cacheOpertionForm.autoLogin
    if (cacheOpertionForm.rememberPassword) {
      if (cacheForm) {
        // form = reactive(cacheForm)
        form.account = cacheForm.account
        form.password = CryptoJS.AES.decrypt(cacheForm.password, secretkey).toString(CryptoJS.enc.Utf8)
      }
    } else {
      if (cacheForm) {
        window.localStorage.removeItem('loginForm')
        window.localStorage.removeItem('opertionForm')
      }
    }
    if (cacheOpertionForm.autoLogin && !route.query.not_auto_login) {
      toHome()
    }
  }
}
const toHome = async () => {
  if (form.account == '') {
    message.warning('请输入账号');
    return
  }
  // if (form.password == '') {
  //   message.warning('请输入密码');
  //   return
  // }
  if (!opertionForm.consent) {
    message.warning('请同意用户协议');
    return
  }

  let data = {
    phone: form.account,
    code: form.code,
    sms_record_id: form.sms_record_id,
    pad_type: 'xinhua01',
    app_version: '1.7.9',
    app_id: 1,
  }
  const { data: res } = await loginApi(data)
  window.localStorage.setItem('userInfo', JSON.stringify(res))
  if (res.edu_school_id == 1) {
    if (opertionForm.rememberPassword || opertionForm.autoLogin) {
      // 简单加密
      form.password = CryptoJS.AES.encrypt(form.password, secretkey).toString()
      window.localStorage.setItem('loginForm', JSON.stringify(form))
    }
    window.localStorage.setItem('opertionForm', JSON.stringify(opertionForm))
  }
  message.success('登录成功')
  const { data: userSubjectData } = await getUserSubject()
  subjectList.value = userSubjectData.list
  let totalScore = 0; // 定义变量用于累加分数
  subjectListData = userSubjectData.list.filter(item => item.subject_id !== 13).map(item => {
    totalScore += item.score;

    // if ((item.subject_id === 1 || item.subject_id === 2) && item.score == 0) {
    //   item.score = 96; // 设置为96
    // } else if (item.score == 0) {
    //   item.score = 80; // 其他设为80
    // }

    return {
      subject_id: item.subject_id,
      score: item.score,
    }
  });
  window.localStorage.setItem('subjectListData', JSON.stringify(subjectListData))
  window.localStorage.setItem('totalScore', totalScore)
  if (totalScore > 0) {
    window.localStorage.setItem('score_dialog', false)
  }

  router.push('/home')


}


const rememberPassword = () => {
  opertionForm.rememberPassword = !opertionForm.rememberPassword
  if (opertionForm.autoLogin && !opertionForm.rememberPassword) {
    opertionForm.autoLogin = false
  }
}

const autoLogin = () => {
  opertionForm.autoLogin = !opertionForm.autoLogin
  if (opertionForm.autoLogin) {
    opertionForm.rememberPassword = true
  }
  // this.toHome()
}
const toHeight = () => {
  let myIframe = document.getElementById("myIframe");
  if (myIframe) {
    let data = {
      form: {
        account: form.account, password: form.password
      },
      opertionForm
    };
    myIframe.contentWindow.postMessage(JSON.stringify(data), highUrl + '/login?fromH=1')
    window.open(highUrl, "_self")
  } else {
    return
  }

}

const toActivate = () => {
  let url = highUrl + '/activate?level=2'
  window.open(url, "_blank")

}
const toRegister = () => {
  router.push('/register')
}

const toLoginP = () => {
  router.push('/login')
}


</script>

<style lang="scss" scoped>
.home_warp {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  background-image: url("../../static/login/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .login_warp {
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 256rem;
      height: 176rem;
      top: -152rem;
      left: 50%;
      margin-left: -125rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    position: relative;
    flex: 1;
    margin: 179rem 560rem 0;
    height: fit-content;
    padding-bottom: 60rem;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 2rem 14rem 0px rgba(141, 184, 227, 0.32);
    border-radius: 20rem;
    border: 20rem solid #dcedfc;
    .title {
      font-size: 40rem;
      margin-top: 52rem;
      // margin-top: 160rem;
      font-weight: 800;
      color: #2196f3;
      text-align: center;
    }
  }
}
.btns_sms {
  background: transparent;
  border: 0;
  color: #2196f3;
}
.icons {
  width: 24rem;
  height: 24rem;
}
.check_warp {
  display: flex;
  margin-top: 20rem;
  div {
    flex: 1;
    color: #666666;
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: right;
    }
  }
}
.btn_warp {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 28rem;
  .btn {
    width: 360rem;
    height: 48rem;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    font-size: 18rem;
    color: white;
    border-radius: 44rem;
    background: #fb9930;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    border: 0;
    margin-right: 0;
  }
  .btn_group {
    margin-top: 6px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    .text:hover {
      color: #2196f3;
      cursor: pointer;
    }
  }
  .btn_text {
    background: #fff;
    // margin-top: 20rem;
    line-height: 24rem;
    padding: 0 4rem;
    text-align: center;
    height: auto;
    cursor: pointer;
    margin-right: 0;
    color: var(--light-666666, var(--neutral-color-666666, #666));
    text-align: center;

    font-size: 20rem;
    font-style: normal;
    font-weight: 400;

    > span {
      // text-decoration: underline;
    }
    &:hover {
      color: #2196f3;
    }
  }
}
:deep .ant-form {
  width: 520rem;
  margin: 0 128rem;
}

:deep .ant-input-affix-wrapper {
  height: 56rem;
  margin-top: 2.78vh;
}
:deep .ant-input-affix-wrapper > input.ant-input {
  font-size: 18rem;
  margin-top: -3rem;
}
:deep .ant-checkbox {
  top: 4.2rem;
}
:deep .ant-checkbox-inner {
  width: 20rem;
  height: 20rem;
  font-size: 28rem;
  border-radius: 4rem;
}
:deep .ant-checkbox + span {
  font-size: 18rem;
  padding-right: 0;
}
:deep .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2196f3;
  border-color: #2196f3;
}
:deep .ant-checkbox-checked .ant-checkbox-inner::after {
  width: 6rem;
  height: 12rem;
  transform: rotate(40deg) scale(1) translate(-51%, -55%);
}
.level {
  margin-top: 20rem;
  font-size: 18rem;
  width: 360rem;
  margin: 20rem auto 0;
  display: flex;
  justify-content: space-around;
  span {
    position: relative;
    padding-left: 25rem;
  }

  span:before {
    width: 18rem;
    height: 18rem;
    content: "";
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    margin-top: -9rem;
    left: 0;
  }
  span.level_active:before {
    border: 3rem solid #2196f3;
  }
  span.level_normal:before {
    border: 1rem solid #ccc;
  }
}
.protocol {
  color: #2196f3;
  font-size: 18rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>