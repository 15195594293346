import { instance } from '@/utils/http.js'

export const rpcAuth = function () {
  return instance({
    url: '/api/rpc/v1/token',
    baseURL: process.env.NODE_ENV === 'development' ? 'http://10.10.11.250:705' : 'https://slbk.sunrayai.cn',
    method: 'get',
    params: {
      appid: 'slzn_admin_tester',
      secret: 'xMG$ARmPlfNqYk)h!sPZ',
      token_type: 'oauth'
    },
  })
}
