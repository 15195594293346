<template>
  <div class="left_wrap">
    <div class="topic_id">
      <div class="id">
        <span>题目ID：{{topic.question_id}}</span>
        <span style="padding-left:24rem">题目分数：<span style="color:#666">{{topic.question_score}}</span></span>
      </div>

      <div class="btn">
        <div class='txt'
             style="cursor:pointer"
             @click="nextTopic(1)">
          <img src="@/static/error/Left.png"
               alt=""
               title="">
          上一题
        </div>
        <div class='txt'
             style="cursor:pointer"
             @click="nextTopic(2)">
          <img src="@/static/error/Right.png"
               alt=""
               title="">
          下一题

        </div>
        <button class="btn_img"
                v-if="topic.has_favorite == 0"
                @click="collect">
          <img src="@/static/error/收藏.png"
               alt="收藏"
               title="收藏">
          收藏
        </button>
        <button class="btn_img"
                v-else
                @click="cancelCollect">
          <img src="@/static/error/收藏实心.png"
               alt="取消收藏"
               title="取消收藏">
          取消
        </button>
        <button class="btn_img"
                @click="showPost"
                v-if="discuss">
          <img src="@/static/error/发帖.png"
               alt="发帖"
               title="发帖">
          发帖
        </button>

      </div>
    </div>

    <div class="list">
      <div class="item">
        <a-collapse v-model:activeKey="activeKey"
                    expand-icon-position="right"
                    :bordered="false">
          <template #expandIcon="{ isActive }">
            <div class="icon_more">
              <img src="@/static/error/Up.png"
                   alt="收起"
                   title="收起"
                   v-show="isActive">
              <img src="@/static/error/Down.png"
                   alt="展开"
                   title="展开"
                   v-show="!isActive">
            </div>

          </template>
          <a-collapse-panel :key="1"
                            header="题目内容">
            <div class="question_tip">题目一
              <span v-show='topic.question_type_name||topic.source_name'>（</span>
              {{topic.question_type_name}}
              <span v-show="topic.question_type_name&&topic.source_name"> ， </span>
              {{topic.source_name}}
              <span v-show='topic.question_type_name||topic.source_name'>）</span>
              <!-- </div> -->
            </div>
            <div class="content"
                 v-html="topic.question_content"></div>
          </a-collapse-panel>
          <a-collapse-panel :key="5"
                            header="我的答案">
            <div class="content"
                 v-html="topic.user_answer"></div>
          </a-collapse-panel>
          <a-collapse-panel :key="2"
                            header="题目答案">
            <div class="content"
                 v-html="topic.question_answer"></div>
          </a-collapse-panel>
          <a-collapse-panel :key="3"
                            header="题目解析">
            <div class="content"
                 v-html="topic.question_explain"></div>
          </a-collapse-panel>
          <!-- <a-collapse-panel key="3"
                            header="我的答案">
            <div class="content"
                 v-html="topic.user_answer"></div>
          </a-collapse-panel> -->

          <a-collapse-panel :key="4"
                            header="我的笔记">
            <template #extra> <button class="btn_img"
                      @click="biji">
                <img src="@/static/error/笔记.png"
                     alt="笔记"
                     title="笔记">
              </button></template>
            <div class="content"
                 v-if="route.query.type==1">{{ topic.comment_to_user||'无'}}</div>

            <div class="content"
                 v-else>{{topic.favorite_tags||'无'}}</div>

          </a-collapse-panel>

        </a-collapse>
      </div>
    </div>
    <Collect ref="collectRef"
             @updateID="updateID" />

  </div>
</template>

<script setup>
import { onMounted, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { message, Modal } from 'ant-design-vue'
import Collect from './collect.vue'
import { delRow } from '@/api/errorBook.js'

let router = useRouter()
let route = useRoute()
let activeKey = ref(['1', '2', '3', '4', '5'])
let iconPosition = ref('right')
let collectRef = ref(null)
let discuss = ref(1)
let has_favorite = ref(null)
let thisTopicId = ref('')
const propData = defineProps({
  topic: {
    type: Object,
    default: () => {
      return {
        question_id: '',
        question_score: '',
        user_score: '',
      }
    }
  },
  subject_id: {
    type: String,
    default: ''
  }

})
const cancelCollect = () => {
  Modal.confirm({
    title: '提示',
    content: '确定是否取消收藏？',
    async onOk () {
      console.log('ok', topic.value);

      await delRow({ ids: topic.value.question_favorite_id })
      topic.value.question_favorite_id = 0
      topic.value.has_favorite = 0
      message.success("取消收藏成功")
      if(route.query.noShow){
        router.go(-1)
      }
    },
    onCancel () {
      collectRef.value.dialogVisible = false
    },
  });
}
const updateID = (id) => {
  topic.value.question_favorite_id = id
  topic.value.has_favorite = 1
}
const collect = () => {
  if (topic.has_favorite) {
    message.warning('已经收藏过啦')
    return
  }
  let form = {
    user_paper_detail_id: topic.value.user_paper_detail_id,
    tags: ''
  }
  collectRef.value.dialogVisible = true
  collectRef.value.setData(form)
}
let { topic, subject_id } = toRefs(propData)
// const emit = defineEmits(['updateInfo'])
let emits = defineEmits(['showPost', 'biji', 'update', 'cancel'])
const showPost = () => {
  emits('showPost')
}
const biji = () => {
  emits('biji')
}

const nextTopic = (num) => {
  // num 1上一题，2下一题 
  if (num == 2) {
    console.log('下一题', topic.value);
    thisTopicId = topic.value.new_question
    if (thisTopicId == null) {
      message.warning("已是最后一题")
      return
    }
    // this.floatData = {}
    // this.$refs.mapRef.cancelSelected()
    emits('cancel')
  } else if (num == 1) {
    thisTopicId = topic.value.last_question_id
    if (thisTopicId == null) {
      message.warning("已是第一题")
      return
    }
    // this.floatData = {}
    // this.$refs.mapRef.cancelSelected()
    emits('cancel')
  }
  window.sessionStorage.setItem("tipic_id", thisTopicId)
  emits('update', thisTopicId)
}
onMounted(() => {
  discuss.value = JSON.parse(window.localStorage.getItem('userInfo')).discuss
  topic.value.has_favorite = route.query.has_favorite
  topic.value.question_favorite_id = route.query.question_favorite_id

})
</script>

<style lang="scss" scoped>
:deep
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-extra {
  margin-left: initial !important;
}
.left_wrap {
  .icon_more {
    img {
      width: 24rem;
      height: 24rem;
    }
  }
  .btn {
    display: flex;
    gap: 12rem;
    margin-top: -9rem;
    .txt {
      display: flex;
      // margin-left: 30rem;
      color: #2b97f0;
      // font-size: 20rem;
      flex-direction: column;
      align-items: center;
      img {
        width: 32rem;
        height: 32rem;
      }
    }
  }
  .btn_img {
    width: 32rem;
    height: 32rem;
    border: 0;
    padding: 0;
    margin: 0;
    // margin-left: 24rem;
    background: transparent;
    color: #2b97f0;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    :deep p {
      font-size: 20rem;
    }
    :deep img {
      vertical-align: middle;
    }
  }

  .topic_id {
    width: 100%;
    display: flex;
    padding: 17rem 40rem;
    justify-content: space-between;
    height: 64rem;
    background: #eff8ff;
    z-index: 1;
    position: relative;
    .id {
      font-size: 20rem;
      // font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 30rem;
    }
  }
  .list {
    height: calc(100vh - 168rem);
    overflow: auto;
    padding: 40rem;

    .question_tip {
      font-size: 20rem;
      // font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 30rem;
      margin-top: 24rem;
    }
  }
}
:deep .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: #fff;
  border-top: 0;
  .content {
    padding: 24rem 0;
  }
}
:deep .ant-collapse {
  font-size: 20rem;
}
:deep .ant-collapse-borderless {
  background-color: #fff;
}
:deep .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0;
  margin-bottom: 24rem;
}
:deep .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 20rem;
  // font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 30rem;
  border-radius: 8rem;
  background: #fafafa;
  padding-left: 16rem;
}
.content {
  :deep table {
    max-width: 100%;
  }
}
</style>